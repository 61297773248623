<template>
  <v-card class="mt-5" flat>
    <v-data-table
      class="datatable px-3 py-2"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="goodsReceipt.type === 1 ? officialHeaders : expectedHeaders"
      hide-default-footer
      :loading="false"
      loading-text="Đang tải dữ liệu"
      :items="goodsReceipt.options"
      item-key="id"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ indexOfOptionIds(item.id) + 1 }}
      </template>

      <template v-slot:[`item.product`]="{ item }">
        <div class="my-1">
          <div class="font-weight-medium">
            {{ item.product && item.product.name }}
          </div>
          <div
            class="text-subtitle-2 font-weight-light grey--text text--darken-2"
            v-html="item.name"
          >
            {{ item.name }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.serials`]="{ item }">
        <div class="mb-1">
          <v-chip
            v-for="(serial, index) in item.serials"
            :key="index"
            class="font-weight-medium cyan lighten-4 mr-1 mt-1 px-2"
            small
          >
            {{ serial }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ item.price | formatCurrency }}
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.serials.length }}
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ (item.price * item.serials.length) | formatCurrency }}
      </template>
    </v-data-table>

    <v-divider class="ma-3"></v-divider>

    <div class="px-6 pb-5" v-if="goodsReceipt.type === 1">
      <div class="d-flex flex-row justify-end font-weight-medium text-total">
        <span class="mr-5">Tổng cộng: </span>
        <span>{{ goodsReceipt.value | formatCurrency }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      expectedHeaders: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product"
        },
        {
          text: "Số lượng dự kiến",
          align: "center",
          sortable: false,
          value: "soluong_dukien"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        }
      ],
      officialHeaders: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product"
        },
        {
          text: "Serials",
          align: "start",
          sortable: false,
          value: "serials"
        },
        {
          text: "Số lượng",
          align: "start",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Đơn giá",
          align: "start",
          sortable: false,
          value: "price"
        },
        {
          text: "Thành tiền",
          align: "start",
          sortable: false,
          value: "total"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },
    optionIds() {
      return this.goodsReceipt.options.map(item => item.id);
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    indexOfOptionIds(value) {
      return this.optionIds.indexOf(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.text-total {
  font-size: 1.125rem;
}
</style>
