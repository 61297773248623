<template>
  <tp-modal v-if="goodsReceipt" name="modal-goods-receipts" max-width="880px">
    <v-card slot-scope="props">
      <v-card-title>
        <div class="font-weight-bold">
          Chi tiết phiếu nhập {{ goodsReceipt.type === 2 ? "dự kiến" : "" }} #{{
            goodsReceipt.code
          }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="goodsReceipt.type === 2"
          color="cyan lighten-5 primary--text rounded-lg text--accent-2 mr-2"
          depressed
          @click="gotoDetailPage()"
        >
          Nhập chính thức
        </v-btn>
        <v-btn
          color="red lighten-5 red--text rounded-lg text--accent-2 mr-2"
          depressed
          @click="cancelGoodsReceipt(props.payload.index)"
        >
          Hủy
        </v-btn>
        <!-- <v-btn class="mr-1" icon color="primary">
          <v-icon>mdi-printer</v-icon>
        </v-btn> -->
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card flat>
          <v-row class="mx-0">
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Chi nhánh</div>
              <div class="py-1">
                {{ goodsReceipt.branch && goodsReceipt.branch.name }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Nhân viên tạo</div>
              <div class="py-1">
                {{
                  goodsReceipt.created_user && goodsReceipt.created_user.name
                }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Ngày tạo</div>
              <div class="py-1">{{ goodsReceipt.created_at }}</div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">
                {{
                  goodsReceipt.type === 1 ? "Chỉnh sửa cuối" : "Ngày về dự kiến"
                }}
              </div>
              <div class="py-1">
                {{
                  goodsReceipt.type === 1
                    ? goodsReceipt.updated_at
                    : goodsReceipt.date_vedukien
                }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Trạng thái</div>
              <div>
                <v-chip
                  class="text-body-2 font-weight-bold px-2"
                  :color="goodsReceipt.status === 1 ? 'green' : 'red accent-2'"
                  outlined
                  small
                >
                  {{ goodsReceipt.status === 1 ? "Đã nhập" : "Đã hủy" }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mt-5 py-4" flat>
          <v-row class="mx-0">
            <v-col class="py-0 px-5">
              <div class="">
                <span class="font-weight-bold">Nhà cung cấp: </span
                >{{ goodsReceipt.supplier && goodsReceipt.supplier.code }}
              </div>
            </v-col>
            <v-col class="py-0 px-5">
              <div class="">
                <span class="font-weight-bold">Công nợ: </span
                >{{ 439248355 | formatCurrency }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-0 mt-2" v-if="goodsReceipt.type === 1">
            <v-col class="py-0 px-5">
              <div class="">
                <span class="font-weight-bold">Hóa đơn NCC: </span
                >{{ goodsReceipt.code_ncc }}
              </div>
            </v-col>
            <v-col class="py-0 px-5">
              <div class="">
                <span class="font-weight-bold">Ngày nhập NCC: </span
                >{{ goodsReceipt.date_ncc }}
              </div>
            </v-col>
          </v-row>
        </v-card>

        <card-products-detail></card-products-detail>

        <v-card v-if="goodsReceipt.note" class="mt-5" flat>
          <div class="amber lighten-5 text-body-1 px-3 py-2">
            <strong>Ghi chú:</strong> {{ goodsReceipt.note }}
          </div>
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import CardProductsDetail from "./components/CardProductsDetail";

export default {
  components: {
    CardProductsDetail
  },
  computed: {
    goodsReceipt() {
      return this.$store.getters["GOODS_RECEIPT/goodsReceipt"];
    },
    goodsReceiptStatusRequest() {
      return this.$store.getters["GOODS_RECEIPT/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    cancelGoodsReceipt(index) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Hủy phiếu nhập <strong>${this.goodsReceipt.code}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Tiếp tục",
            onClickHandler: async () => {
              await this.$store.dispatch("GOODS_RECEIPT/cancelGoodsReceipt", {
                id: this.goodsReceipt.id,
                index: index
              });
              if (
                this.goodsReceiptStatusRequest.value ===
                "success-cancelGoodsReceipt"
              ) {
                this.$modal.hide({ name: "modal-goods-receipts" });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã hủy phiếu nhập"
                  }
                });
              }
            }
          }
        }
      });
    },

    closeModal() {
      this.$modal.hide({ name: "modal-goods-receipts" });
    },

    async gotoDetailPage() {
      // Set goods receipt
      await this.$store.dispatch("GOODS_RECEIPT/setGoodsReceipt", {
        id: this.goodsReceipt.id,
        branch_id: this.goodsReceipt.branch.id,
        supplier_id: this.goodsReceipt.supplier.id,
        type: 1,
        note: this.goodsReceipt.note,
        date_ncc: null,
        code_ncc: null,
        serials: [],
        options: this.goodsReceipt.options.map(item => {
          return {
            id: item.id,
            SKU: item.SKU,
            product_name: item.product.name,
            name: item.name,
            price: null,
            serials: []
          };
        })
      });
      // Go to detail page
      await this.$router.push({
        name: "transaction_goods-receipts-create-official",
        params: {
          goodsReceiptID: this.goodsReceipt.id
        }
      });
      this.closeModal();
    }
  }
};
</script>
